import React, { useState } from "react";
import AudioPlayer from "../components/alafasy/Audio";
import AudioPlayerContext from "./audioPlayerContext";

export default function AudioPlayerProvider(props) {
  const [songs, setSongs] = useState([]);
  const [songIndex, setSongIndex] = useState(-1);
  const [author, setAuthor] = useState("");
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  const currentSong = () => {
    if (songIndex < 0) return null;
    return songs[songIndex];
  };

  // Ensure playing state is maintained on next track
  const handleNext = () => {
    setSongIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      if (nextIndex < songs.length) {
        setPlaying(true); // Ensure playback continues
        return nextIndex;
      }
      return prevIndex; // Stay on the last song
    });
  };

  const handlePrev = () => {
    setSongIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    setPlaying(true); // Ensure playback continues
  };

  const audioContext = {
    songs,
    setSongs,
    songIndex,
    setSongIndex,
    author,
    setAuthor,
    playing,
    setPlaying,
    progress,
    setProgress,
    currentSong,
    onNext: handleNext,
    onPrev: handlePrev,
  };

  return (
    <AudioPlayerContext.Provider value={audioContext}>
      {props.children}
      {songIndex > -1 && (
        <AudioPlayer
          currentSong={songs[songIndex]}
          author={author}
          songCount={songs.length}
          songIndex={songIndex}
          onNext={handleNext}
          onPrev={handlePrev}
          playing={playing}
          setPlaying={setPlaying}
          setProgress={setProgress}
        />
      )}
    </AudioPlayerContext.Provider>
  );
}
