import * as React from "react";
import AudioProgressBar from "./AudioProgressBar";
import VolumeInput from "../player/VolumeInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faPauseCircle, faForwardStep, faBackwardStep, faVolumeUp, faVolumeOff } from '@fortawesome/free-solid-svg-icons';
import { formatDurationDisplay } from "../audio/util";
import { useTranslation } from "gatsby-plugin-react-i18next";

const useWindowSize = () => {
    const [windowSize, setWindowSize] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    React.useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
};

export default function AudioPlayer(props) {
    const { currentSong, author, songCount, songIndex, onNext, onPrev, playing, setPlaying, setProgress } = props;
    const { t } = useTranslation();
    const { width } = useWindowSize();
    const audioRef = React.useRef(null);

    const [volume, setVolume] = React.useState(0.2);
    const [duration, setDuration] = React.useState(0);
    const [currentProgress, setCurrentProgress] = React.useState(0);
    const [buffered, setBuffered] = React.useState(0);

    // Sync play state with parent component
    React.useEffect(() => {
        if (audioRef.current) {
            if (playing) {
                audioRef.current.play().catch(err => console.error("Playback error:", err));
            } else {
                audioRef.current.pause();
            }
        }
    }, [playing]);

    const togglePlayPause = () => {
        setPlaying(!playing); // Toggle play/pause and notify the parent component
    };

    // Update UI when song changes (without interrupting playback)
    React.useEffect(() => {
        if (audioRef.current) {
            audioRef.current.load();
        }
    }, [currentSong]);

    React.useEffect(() => {
        setProgress(currentProgress);
    }, [currentProgress, setProgress]);

    // Ensure smooth transition to the next track when one ends
    React.useEffect(() => {
        if (!audioRef.current) return;
        audioRef.current.pause();
        setTimeout(() => {
            audioRef.current.play().catch(err => console.error("Playback error:", err));
        }, 500);
    }, [songIndex]);

    const handleVolumeChange = (volumeValue) => {
        if (!audioRef.current) return;
        audioRef.current.volume = volumeValue;
        setVolume(volumeValue);
    };

    const handleMuteUnmute = () => {
        if (!audioRef.current) return;
        audioRef.current.volume = audioRef.current.volume === 0 ? volume : 0;
    };

    const handleBufferProgress = (e) => {
        const audio = e.currentTarget;
        const dur = audio.duration;
        if (dur > 0) {
            for (let i = 0; i < audio.buffered.length; i++) {
                if (audio.buffered.start(audio.buffered.length - 1 - i) < audio.currentTime) {
                    setBuffered(audio.buffered.end(audio.buffered.length - 1 - i));
                    break;
                }
            }
        }
    };

    const durationDisplay = formatDurationDisplay(duration);
    const elapsedDisplay = formatDurationDisplay(currentProgress);

    // Only render the sticky player if a track is selected
    if (!currentSong?.trackName || currentSong.trackName === "Select a track") {
        return null; // Return null to not render the sticky player if no track is selected
    }

    return (
        <div className="sticky-player">
            <div className="progress-bar-container">
                <AudioProgressBar
                    duration={duration}
                    currentProgress={currentProgress}
                    buffered={buffered}
                    onChange={(e) => {
                        if (!audioRef.current) return;
                        audioRef.current.currentTime = e.currentTarget.valueAsNumber;
                        setCurrentProgress(e.currentTarget.valueAsNumber);
                    }}
                />
                <audio
                    ref={audioRef}
                    preload="metadata"
                    onDurationChange={(e) => setDuration(e.currentTarget.duration)}
                    onEnded={onNext}
                    onCanPlay={(e) => e.currentTarget.volume = volume}
                    onTimeUpdate={(e) => {
                        setCurrentProgress(e.currentTarget.currentTime);
                        handleBufferProgress(e);
                    }}
                    onPlaying={() => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                    onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
                    onProgress={handleBufferProgress}
                >
                    <source type="audio/mpeg" src={currentSong?.downloadLink} />
                </audio>
            </div>
            <div className="player-controls-section">
                <div className="player-controls-section--wrapper">
                    {/* Conditionally render track meta if a track is selected */}
                    {currentSong?.trackName !== "Select a track" && (
                        <div className="controls-track-meta">
                            <div className="controls-track-meta--title">{currentSong?.trackName}</div>
                            <div className="controls-track-meta--artist">{author}</div>
                        </div>
                    )}
                    <div className="controls-main">
                        <div className="controls-main--wrapper">
                            {songIndex > 0 ? (
                                <FontAwesomeIcon icon={faBackwardStep} className="icon-backward" onClick={onPrev} />
                            ) : (
                                <FontAwesomeIcon icon={faBackwardStep} className="icon-backward-disabled" />
                            )}
                            {playing ? (
                                <FontAwesomeIcon icon={faPauseCircle} className="icon-circle" onClick={togglePlayPause} />
                            ) : (
                                <FontAwesomeIcon icon={faPlayCircle} className="icon-circle" onClick={togglePlayPause} />
                            )}
                            {songIndex < songCount - 1 ? (
                                <FontAwesomeIcon icon={faForwardStep} className="icon-forward" onClick={onNext} />
                            ) : (
                                <FontAwesomeIcon icon={faForwardStep} className="icon-forward-disabled" />
                            )}
                        </div>
                    </div>
                    <div className="controls-right-section">
                        <div className="controls-right-section--timer">
                            <div className="controls-right-section--timer-progress">{elapsedDisplay}</div>
                            <div className="controls-right-section--timer-separator">/</div>
                            <div className="controls-right-section--timer-duration">{durationDisplay}</div>
                        </div>
                        {width > 600 && (
                            <div className="controls-right-section--right">
                                <FontAwesomeIcon 
                                    icon={volume === 0 ? faVolumeOff : faVolumeUp} 
                                    className="icon-volume" 
                                    onClick={handleMuteUnmute} 
                                />
                                <VolumeInput volume={volume} onVolumeChange={handleVolumeChange} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
